<i18n lang="yaml">
pt:
  seeMore: 'Ver mais'
  typeMessage: 'Digite uma mensagem...'
  greetingMessage: 'Olá! Como posso ajudar você hoje?'
en:
  seeMore: 'See more'
  typeMessage: 'Type a message...'
  greetingMessage: 'Hello! How can I help you today?'
</i18n>

<template>
  <v-navigation-drawer
    v-model="isOpen"
    touchless
    :width="450"
    permanent
    class="ai-chat"
  >
    <div
      class="ai-chat__content"
      :class="{ 'ai-chat__content--extra-bottom-space': extraBottomSpace }"
    >
      <div
        ref="chatBody"
        class="ai-chat__body"
      >
        <div class="d-flex justify-center">
          <deck-button
            v-if="hasPreviousMessages"
            :text="t('seeMore')"
            kind="primary"
            size="small"
            :loading="isLoadingPreviousMessages"
            :disabled="isLoadingPreviousMessages"
            @click="fetchPreviousMessages"
          />
        </div>

        <div
          v-if="showGreetingMessage"
          class="ai-chat__message"
        >
          {{ t('greetingMessage') }}
        </div>

        <deck-inputs-text-wizard-input
          v-for="(message, index) in messages"
          :key="index"
          :class="{ 'ai-chat__message--user': message.role === 'user' }"
          :model-value="message.content"
          as-text
          class="ai-chat__message"
        />

        <div
          v-if="isLoading"
          class="ai-chat__message"
        >
          <span class="ai-chat__message-dot">.</span><span class="ai-chat__message-dot">.</span><span class="ai-chat__message-dot">.</span>
        </div>
      </div>

      <div class="ai-chat__footer">
        <deck-text-field
          v-model="inputValue"
          :loading="isLoading"
          :placeholder="t('typeMessage')"
          fixed-controls
          autocomplete="off"
          size="large"
          :controls="[{
            color: 'primary',
            controlName: 'send',
            disabled: isLoading || !inputValue || inputValue.length === 0,
            icon: 'fa-arrow-up',
            isReady: true,
          }]"
          @control:click="$event === 'send' ? sendMessage() : undefined"
          @keydown.enter="sendMessage"
        />
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script lang="ts" setup>
import { useBroadcastChannel } from '@vueuse/core';
import type { ComponentInternalInstance } from 'vue';
import { mapState, mapActions, mapMutations } from '~/assets/javascript/modules/vuex';

defineComponent({
  name: 'AIChat',
});

const {
  isOpen,
  extraBottomSpace,
  messages,
  hasPreviousMessages,
} = mapState('ai', ['isOpen', 'extraBottomSpace', 'messages', 'hasPreviousMessages']);

const {
  sendMessage: sendMessageAction,
  getMessages,
  getPreviousMessages,
} = mapActions('ai', ['sendMessage', 'getMessages', 'getPreviousMessages']);

const { setMessages } = mapMutations('ai', ['setMessages']);

const {
  data,
  post,
} = useBroadcastChannel({ name: 'aiChat' });

const { $scrollTo, $errorRescue } = useNuxtApp();

let vm: ComponentInternalInstance | null = null;
const isLoading = ref(false);
const isLoadingPreviousMessages = ref(false);
const showGreetingMessage = ref(false);
const inputValue = ref('');
const chatBody = useTemplateRef('chatBody');
const lastBroadcastReceivedMessages = ref([]);
let previousLastMessage = '';

const scrollBottom = async () => {
  await nextTick();

  if (!chatBody.value) return;

  $scrollTo({
    top: chatBody.value.scrollHeight,
    scrollerElement: chatBody.value,
  });
};

watch(data, (payload) => {
  const parsedData = JSON.parse(payload as string);

  switch (parsedData.type) {
    case 'messages': {
      lastBroadcastReceivedMessages.value = parsedData.value;

      if (_isEqual(messages.value, lastBroadcastReceivedMessages.value)) return;

      setMessages(lastBroadcastReceivedMessages.value);
      break;
    }

    case 'loading': {
      if (isLoading.value === parsedData.value) return;

      isLoading.value = parsedData.value;
      break;
    }

    default:
      throw new Error(`Invalid aiChat broadcast message type: ${parsedData.type}`);
  }
});

watch(isLoading, () => {
  // prevent first load to show loading on other tabs that are open and already is displaying messages or greetingMessage
  if (messages.value.length > 0) post(JSON.stringify({ type: 'loading', value: isLoading.value }));
  scrollBottom();
});

watch(messages, () => {
  const lastMessage = messages.value[messages.value.length - 1]?.content;

  if (lastMessage !== previousLastMessage) scrollBottom();

  previousLastMessage = lastMessage;

  if (_isEqual(messages.value, lastBroadcastReceivedMessages.value)) return;

  post(JSON.stringify({ type: 'messages', value: messages.value }));
});

const fetchMessages = async () => {
  try {
    isLoading.value = true;
    await getMessages();
    showGreetingMessage.value = messages.value.length === 0;
  } catch (error) {
    $errorRescue(vm, error, 'getAIMessages');
  } finally {
    isLoading.value = false;
  }
};

onMounted(() => {
  vm = getCurrentInstance();
  if (isOpen.value && messages.value.length === 0) fetchMessages();
});

watch(isOpen, () => {
  if (isOpen.value && messages.value.length === 0) {
    fetchMessages();
  }
});

const { t } = useI18n();

const sendMessage = async () => {
  if (isLoading.value || !inputValue.value || inputValue.value.length === 0) return;

  isLoading.value = true;
  const message = inputValue.value;

  try {
    inputValue.value = '';
    await sendMessageAction(message);
  } catch (error) {
    inputValue.value = message;
    $errorRescue(vm, error, 'sendAIMessage');
  }

  isLoading.value = false;
};

const fetchPreviousMessages = async () => {
  try {
    isLoadingPreviousMessages.value = true;
    await getPreviousMessages();
  } catch (error) {
    $errorRescue(vm, error, 'getPreviousAIMessages');
  } finally {
    isLoadingPreviousMessages.value = false;
  }
};
</script>

<style lang="scss">
.ai-chat {
  background: transparent;
  max-height: 100dvh;
  max-width: 100dvw;
}

.ai-chat__content {
  display: flex;
  flex-direction: column;
  gap: var(--z-s4);
  height: 100%;
  width: 100%;
  padding-block: var(--z-s4);
  transition: padding-bottom 0.2s ease-in-out;
}

.ai-chat__content--extra-bottom-space {
  padding-bottom: calc(var(--z-dock-height) + var(--z-dock-margin) * 2);
}

.ai-chat__body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--z-s2);
  overflow-y: auto;
  padding-inline: var(--z-s4);
}

.ai-chat__message {
  background: var(--z-theme-surface-highlight);
  border-radius: var(--z-border-radius-inner-base);
  color: var(--z-theme-text);
  background: rgba(var(--z-theme-surface-highlight-rgb), 0.7);
  hyphens: auto;
  max-width: 80%;
  padding: var(--z-s2);
  text-wrap: pretty;
  width: fit-content;
}

.ai-chat__message--user {
  align-self: flex-end;
  background: rgba(var(--z-theme-text-rgb), 0.7);
  color: var(--z-theme-surface);
}

.ai-chat__message-dot {
  animation: aiChatWave 1s infinite;
  display: inline-block;

  &:nth-child(1) {
    animation-delay: 0.45s;
  }

  &:nth-child(2) {
    animation-delay: 0.3s;
  }

  &:nth-child(3) {
    animation-delay: 0.15s;
  }
}

@keyframes aiChatWave {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.ai-chat__footer {
  padding-inline: var(--z-s4);
}

.ai-chat__loading-icon {
  animation: aiChatLoading 1s infinite;
}

@keyframes aiChatLoading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
